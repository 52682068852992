/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Money } from '@Types/product/Money';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import Price from '../../../../commercetools-ui/price';
import IconTooltip from '../../../tooltip';
import { Tooltip as TooltipDetails } from '../../../types/Tooltip';
import { TooltipHandler } from '../../../utils/TooltipHandler';

export interface Props {
  readonly price: Money;
  readonly recommendedPrice?: Money;
  readonly discountedPrice?: Money;
  readonly showDiscountPercentage?: boolean;
  readonly useDifferentialTaxation?: boolean;
  readonly tooltips?: TooltipHandler | TooltipDetails[];
}

const PriceInfo = ({
  price,
  recommendedPrice,
  discountedPrice,
  showDiscountPercentage = true,
  useDifferentialTaxation = false,
  tooltips = [],
}: Props) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const discount =
    showDiscountPercentage && recommendedPrice
      ? ((recommendedPrice.centAmount - (discountedPrice?.centAmount ?? price.centAmount)) /
          recommendedPrice.centAmount) *
        100
      : undefined;

  if (Array.isArray(tooltips)) {
    tooltips = new TooltipHandler(tooltips);
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="flex w-full items-start justify-between lg:flex-row-reverse">
          {discount && (
            <div className="rounded-4xl bg-accent-300 px-1 text-sm font-bold">
              {discount.toFixed(2).replace('.', ',')}%&nbsp;
              {formatMessage({ id: 'cheaper', defaultMessage: 'günstiger' })}
            </div>
          )}
          <Price
            price={discountedPrice ?? price}
            className={`text-${discountedPrice ? 'accent-400' : 'gray-900'} font-headline text-xl`}
          />
        </div>
        <div className="flex w-full items-center justify-between lg:flex-row-reverse">
          {recommendedPrice && (
            <div className="flex items-center text-xs font-normal">
              {formatMessage({ id: 'uvp', defaultMessage: 'ehem. Neupreis' })}: &nbsp;
              <Price price={recommendedPrice} className="font-normal" />
              {tooltips.hasTooltip('recommendedPrice') && (
                <IconTooltip
                  tooltip={tooltips.getTooltip('recommendedPrice')}
                  wrapperClassNames="ml-2"
                  contentClassNames="text-xs"
                  iconClassNames="size-6 text-neutral-950 pointer-events-none"
                />
              )}
            </div>
          )}
          <div
            className="text-xs font-normal"
            dangerouslySetInnerHTML={{
              __html: useDifferentialTaxation
                ? formatMessage({
                    id: 'vatDiffInfo',
                    defaultMessage:
                      'Artikel unterliegt der Differenzbesteuerung gem. § 25a UStG, keine MWSt. ausweisbar ggf. zzgl. Versandkosten',
                  })
                : formatMessage({ id: 'vatInfo', defaultMessage: 'inkl. MwSt. ggf. zzgl. Versandkosten' }),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PriceInfo;
