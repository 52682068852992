import React, { useEffect, useRef, useState } from 'react';
import Image, { ImageIntrinsicSize, MediaItemWithMods } from 'frontastic/lib/image';
import { Reference, ReferenceLink } from 'helpers/reference';
import useMatchMediaQuery from 'helpers/hooks/useMatchMediaQuery';

interface ImageScrollerProps {
  columnCount: number;
  images: Array<{
    media: MediaItemWithMods;
    imageIntrinsicSize?: ImageIntrinsicSize;
    name?: string;
    enableLink?: boolean;
    reference?: Reference;
  }>;
}

const ImageScroller: React.FC<ImageScrollerProps> = ({ columnCount = 7, images }) => {
  const containerRef = useRef(null);

  const [atStart, setAtStart] = useState(false);
  const [atEnd, setAtEnd] = useState(false);
  const isMobileSize = useMatchMediaQuery('(max-width: 767px)');

  const handleScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const containerWidth = container.offsetWidth;
      const contentWidth = container.scrollWidth;

      setAtStart(container.scrollLeft === 0);
      setAtEnd(container.scrollLeft + containerWidth === contentWidth);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      if (isMobileSize) {
        container.scrollLeft = (container.scrollWidth - container.offsetWidth) / 2;
      }

      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const gradientClass = `absolute top-0 h-full w-1/6 pointer-events-none transition-opacity duration-300 from-white to-transparent lg:hidden`;
  const imageClasses =
    'flex h-7 w-[110px] shrink-0 items-center justify-center px-2 md:h-10 lg:max-w-[150px] xl:px-5 xl:py-2 lg:w-full';

  return (
    <div className="relative left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] w-screen overflow-hidden py-2 lg:left-0 lg:right-0 lg:ml-0 lg:mr-0 lg:w-full">
      <div className={`${gradientClass} left-0 bg-gradient-to-r ${atStart ? 'opacity-0' : 'opacity-100'}`} />
      <div className={`${gradientClass} right-0 bg-gradient-to-l ${atEnd ? 'opacity-0' : 'opacity-100'}`} />
      <div
        ref={containerRef}
        className="flex overflow-x-auto px-6 py-4 lg:grid lg:gap-5 lg:px-0"
        style={{
          gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
          scrollSnapType: 'x mandatory',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {images.slice(0, columnCount).map((image, index) => (
          <div key={index} className="flex w-full items-center justify-center">
            {image.enableLink ? (
              <ReferenceLink target={image.reference} className="cursor-pointer">
                <div className={imageClasses}>
                  <img
                    src={image.media.media.file}
                    className="max-h-full max-w-full object-contain"
                    draggable={false}
                  />
                </div>
              </ReferenceLink>
            ) : (
              <div className={imageClasses}>
                <img src={image.media.media.file} className="max-h-full max-w-full object-contain" draggable={false} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageScroller;
